import { toArray } from '../utils/toArray';

const initialState = {
  customerIds: [],
};

export default function customersListReducer(state = initialState, action) {
  switch (action.type) {
    case 'CUSTOMER_SEARCH_FETCH_REQUEST':
      return { ...state };
    case 'CUSTOMER_SEARCH_FETCHED':
    case 'CUSTOMERS_FETCHED':
      return {
        ...state,
        customerIds: action.result
          ? toArray(action.result)
          : initialState.customerIds,
      };
    default:
      return state;
  }
}
