import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { change, formValueSelector, reduxForm } from 'redux-form';
import {
  createOrUpdateRevenue,
  fetchStatement,
} from '../../processes/statementProcesses';
import { fetchCustomers } from '../../processes/customerProcessess';
import { getStatement } from '../../selectors/statementSelectors';
import RevenueForm from './RevenueForm';
import { getAllCustomers } from '../../selectors/customerSelectors';
import {
  getConfig,
  getConfigByName,
  getCurrencyById,
  getStatementCategoriesByType,
} from '../../selectors/configSelectors';
import { getCurrentUserCompany } from '../../selectors/userSelectors';

const FORM_NAME = 'revenue';

function RevenueFormContainer(props) {
  const { dispatch, statementId, statement } = props;
  useEffect(() => {
    fetchCustomers(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (statement || !statementId) return;
    fetchStatement(dispatch, statementId);
  }, [statement, statementId]);

  return <RevenueForm {...props} />;
}

function mapStateToProps(state, ownProps) {
  const statementId =
    ownProps?.statementId || ownProps?.match?.params?.statementId;
  const statement = ownProps?.statement || getStatement(state, statementId);
  const selector = formValueSelector(FORM_NAME);
  const revenueCategoryId = getConfigByName(state, 'revenueCategoryId');
  const company = getCurrentUserCompany(state);
  const currency = getCurrencyById(state, company?.currencyId);

  return {
    initialValues: {
      orderId: ownProps?.orderId,
      ...statement,
      type: 'revenue',
      orderStatement: statement?.orderStatement || 'true',
    },
    customers: getAllCustomers(state),
    statementId,
    statement,
    orderStatement: selector(state, 'orderStatement'),
    orderReasons: getConfig(state, 'orderReasons'),
    paymentMethods: getConfig(state, 'paymentMethods'),
    statementCategories: getStatementCategoriesByType(state, 'revenue'),
    revenueCategoryId,
    currency,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_NAME,
    onSubmit: createOrUpdateRevenue,
    onChange: (values, dispatch) => {
      dispatch(
        change(
          FORM_NAME,
          values.orderStatement == 'true' ? 'statementCategoryId' : 'orderId',
          null,
        ),
      );
    },
    enableReinitialize: true,
  })(RevenueFormContainer),
);
