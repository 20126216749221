import React from 'react';
import EmptyState from '../library/EmptyState';
import AddButton from '../library/AddButton';
import RevenueListItemContainer from './RevenueListItemContainer';
import DefaultIcon from '../library/icons/DefaultIcon';

const wrapperSx = {
  display: 'flex',
  marginBottom: '16px',
};

export default function RevenuesList({ revenues, onAdd }) {
  return revenues.length ? (
    <>
      <div style={wrapperSx}>
        <AddButton onClick={onAdd} wide text="Adicionar recebimento" />
      </div>
      {revenues.map((revenue) => (
        <RevenueListItemContainer key={revenue.id} revenue={revenue} />
      ))}
    </>
  ) : (
    <EmptyState
      iconUrl={DefaultIcon}
      title="Lista Vazia"
      description="Nenhum recebimento cadastrado"
      cta="/statements/revenues/new"
      ctaLabel="Cadastrar recebimento"
    />
  );
}
