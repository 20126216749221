import React from 'react';
import EmptyState from '../library/EmptyState';
import Page from '../page/Page';
import OrderListItemContainer from './OrderListItemContainer';
import SupplyIcon from '../library/images/supply.png';
import BackToTop from '../library/BackToTop';
import OrderSearchFormContainer from './OrderSearchFormContainer';
import { Separator } from '../library';
import Pagination from '../library/Pagination';

const paginationWrapper = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
};

export default function OrdersPage({
  orders,
  onAdd,
  currency,
  loading,
  currentPage,
  totalPages,
  totalCount,
  onPageChange,
  isMobile,
}) {
  return (
    <Page title="Vendas e pedidos" back="/" headerType="secondary">
      <OrderSearchFormContainer onAddButton={onAdd} />
      <Separator />
      {!loading && orders.length ? (
        <>
          {/* <BackToTop /> */}
          {orders.map((order) => (
            <OrderListItemContainer
              key={`order${order.id}`}
              order={order}
              currency={currency}
            />
          ))}
          <div style={paginationWrapper}>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              totalCount={totalCount}
              onPageChange={onPageChange}
              short={isMobile}
            />
          </div>
        </>
      ) : (
        <EmptyState
          iconUrl={SupplyIcon}
          loading={loading}
          title="Nenhum pedido cadastrado"
          cta="/orders/new"
          ctaLabel="Cadastrar pedido"
        />
      )}
    </Page>
  );
}
