import React from 'react';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import Separator from './Separator';
import Segment from './Segment';
import { decorationColors, spacing } from './theme';
import Typography from './Typography';
import HorizontalItems from './HorizontalItems';
import HorizontalItem from './HorizontalItem';

const colors = [
  '#F1E15B',
  '#E8A838',
  '#61CDBB',
  '#97E3D5',
  '#E8C1A0',
  '#F47560',
  '#86A5F6',
  '#969009',
];
const segmentSx = {
  minHeight: '150px',
  marginBottom: spacing.m,
  borderRadius: spacing.xs,
  display: 'flex',
  flexDirection: 'column',
};
const chartContainerSx = { display: 'flex', justifyContent: 'center' };
const titleWrapper = {
  display: 'flex',
  justifyContent: 'flex-start',
  marginTop: spacing.xs,
};
const titleStyle = {
  color: 'black',
};
const itemsTitleStyle = {
  fontSize: 14,
};
const containerStyle = {
  borderBottom: 'none',
};
const descriptionStyle = {
  color: decorationColors.decorationBrand,
  fontWeight: 'bold',
};
const arcLabelSx = {
  [`& .${pieArcLabelClasses.root}`]: {
    fill: 'black',
    fontWeight: 'bold',
  },
};
const separatorSx = {
  width: '-webkit-fill-available',
};

export default function PizzaChart({ data, index, title }) {
  const graphData = data.slice(0, 8).map((item) => ({
    id: index,
    value: item?.ordersSales || item?.productsSales,
    label: item?.name,
  }));
  const otherData = data.slice(8).map((data) => ({
    title: `${data?.name}`,
    description: `${data?.ordersSales || data?.productsSales}`,
  }));

  return (
    <Segment sx={segmentSx}>
      <div style={titleWrapper}>
        <Typography weight="bold" style={titleStyle}>
          {title}
        </Typography>
      </div>
      <div style={chartContainerSx}>
        <PieChart
          width={310}
          height={250}
          colors={colors}
          slotProps={{
            legend: {
              hidden: true,
              direction: 'row',
              position: { vertical: 'bottom' },
              itemMarkHeight: 2,
              itemMarkWidth: 15,
              markGap: 10,
            },
          }}
          series={[
            {
              data: graphData,
              arcLabel: (item) => `${item.value}`,
              arcLabelMinAngle: 20,
              innerRadius: 60,
              outerRadius: 100,
              paddingAngle: 0,
              cornerRadius: 5,
              cx: 150,
            },
          ]}
          sx={arcLabelSx}
        />
      </div>
      {otherData.length > 0 && (
        <>
          <Separator style={separatorSx} />
          <HorizontalItem
            title="Outras categorias:"
            description="Nº vendidos"
            containerStyle={containerStyle}
            titleStyle={{ color: 'black', fontWeight: 'bold' }}
            descriptionStyle={{ fontSize: 12, fontWeight: 'bold' }}
          />
          <HorizontalItems
            titleStyle={itemsTitleStyle}
            containerStyle={containerStyle}
            descriptionStyle={descriptionStyle}
            items={otherData}
          />
        </>
      )}
    </Segment>
  );
}
