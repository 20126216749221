import { connect } from 'react-redux';
import CustomerSearchForm from './CustomerSearchForm';
import { submitSearchFilter } from '../../processes/customerProcessess';
import { change, formValueSelector, reduxForm } from 'redux-form';
import debounce from '../../utils/debounce';
import React, { useCallback } from 'react';

const FORM_NAME = 'customerSearch';

function CustomerSearchFormContainer(props) {
  const { dispatch } = props;
  const clearHandler = useCallback(() => {
    dispatch(change(FORM_NAME, 'nameCont', ''));
  }, [dispatch]);

  return <CustomerSearchForm {...props} clearHandler={clearHandler} />;
}

function mapStateToProps(state) {
  const formSelector = formValueSelector(FORM_NAME);
  const nameCont = formSelector(state, 'nameCont');
  return { initialValues: {}, nameCont };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_NAME,
    onChange: debounce(submitSearchFilter, 750),
  })(CustomerSearchFormContainer),
);
