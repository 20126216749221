import React from 'react';
import { Grid } from '@mui/material';
import Button from '../library/Button';
import CardActions from '../library/cards/CardActions';
import { spacing } from '../library/theme';
import {
  CardForm,
  DateField,
  RadioField,
  TextField,
  SearchDropDownField,
} from '../library/forms';
import { cnpjMask, cpfMask, phoneMask } from '../../utils/inputMasks';
import makeDropDownOptions from '../../utils/makeDropDownOptions';
import { Box } from '../library';

const fieldGroupSx = {
  marginTop: spacing.m,
};
const radioSx = {
  display: 'flex',
  gap: 3,
};

export default function CustomerForm(props) {
  const { isCompany } = props;
  const documentMask = isCompany ? cnpjMask : cpfMask;
  const phoneNumberMask = props.skipBrazilianPhoneMask ? {} : phoneMask;

  return (
    <CardForm {...props}>
      <Grid container spacing={2} sx={fieldGroupSx} columnSpacing={spacing.m}>
        <Grid item xs={12}>
          <Box sx={radioSx}>
            <RadioField name="isCompany" value="false" label="Pessoa física" />
            <RadioField name="isCompany" value="true" label="Pessoa jurídica" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="name"
            label={isCompany ? 'Razão social' : 'Nome completo'}
            required
            fluid
          />
        </Grid>
        <Grid item xs={12}>
          <SearchDropDownField
            name="phoneCountryCodeId"
            label="Código do País"
            options={makeDropDownOptions(props.phoneCountryCodes)}
            fluid
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="phone"
            label="Celular (WhatsApp)"
            placeholder="(99) 9 9999-9999"
            fluid
            {...phoneNumberMask}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="document"
            label={isCompany ? 'CNPJ' : 'CPF'}
            fluid
            {...documentMask}
          />
        </Grid>
        {isCompany && (
          <Grid item xs={12}>
            <TextField name="fantasyName" label="Nome fantasia" fluid />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            name="instagram"
            label="Instagram"
            placeholder="ex: @nome"
            fluid
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="address"
            label="Endereço"
            placeholder="ex: Endereço, 123 - Bairro, Cidade / UF"
            fluid
          />
        </Grid>
        <Grid item xs={12}>
          <DateField
            name={isCompany ? 'billingPeriod' : 'birthday'}
            label={isCompany ? 'Prazo do boleto' : 'Data de nascimento'}
            placeholder="Ex: 12/12/2012"
            fluid
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="howDidMeet"
            placeholder="Conte como nos conheceu"
            rows={3}
            multiline
            fluid
          />
        </Grid>
      </Grid>
      <CardActions>
        <Button
          type="button"
          onClick={props.handleSubmit}
          color="success"
          size="large"
          disabled={props.pristine || props.submitting}
          fluid
        >
          {props.customer ? 'Atualizar' : 'Adicionar cliente'}
        </Button>
      </CardActions>
    </CardForm>
  );
}
