import React, { useCallback, useState } from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { css, style } from 'glamor';
import theme, { typographyColors } from './theme';
import { makeIsMobile } from '../../utils/useScreenWidth';
import { makeStyles } from '@material-ui/styles';

const BUTTON_SIZE = 38;
const positionClass = css({
  height: BUTTON_SIZE,
});
const buttonSx = {
  '& .MuiFab-primary': {
    width: BUTTON_SIZE,
    height: BUTTON_SIZE,
    backgroundColor: theme.palette.add.main,
    color: typographyColors.inverseTitle,
    '&:hover': { backgroundColor: theme.palette.add.active },
  },
};
const useStyles = makeStyles(() => ({
  staticTooltipLabel: {
    backgroundColor: '#6D6D6D',
    color: typographyColors.inverseTitle,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
  },
}));

const ActionItem = ({ action, afterActionCallback, ...rest }) => {
  const isMobile = makeIsMobile();
  const classes = useStyles();

  const actionClickHandler = useCallback(() => {
    action.onClick();
    afterActionCallback();
  }, [action]);

  return (
    <SpeedDialAction
      key={action.name}
      icon={action.icon}
      tooltipTitle={action.name}
      tooltipOpen={isMobile}
      classes={classes}
      onClick={actionClickHandler}
      {...rest}
    />
  );
};

export default function CustomSpeedDial({ actions, sx }) {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <div className={css(positionClass, open && { zIndex: 2 })} style={sx}>
      <SpeedDial
        ariaLabel="SpeedDial"
        icon={<SpeedDialIcon />}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        sx={buttonSx}
        direction="down"
      >
        {actions.map((action) => (
          <ActionItem action={action} afterActionCallback={handleClose} />
        ))}
      </SpeedDial>
    </div>
  );
}
