import React from 'react';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import theme from '../library/theme';
import { toCurrency } from '../../utils';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Modal } from '../library';
import RevenueFormContainer from './RevenueFormContainer';
import ExpenseFormContainer from './ExpenseFormContainer';

const tableBodyRowSx = {
  borderRadius: '10px',
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    border: 'none',
    color: theme.palette.border.hover,
  },
}));
const tableBodyTitleSx = {
  fontWeight: 'bold',
  flex: 1,
};

const getRevenueTitle = (statement, statementCategory) =>
  statement?.meta?.orderName || statementCategory;

const getExpenseTitle = (statement, statementCategory) =>
  statement?.title || statementCategory;

export default function StatementsTableRow(props) {
  const {
    statement,
    statementCategory,
    actions,
    anchorEl,
    open,
    onOpen,
    onClose,
    onDelete,
    onEdit,
    openStatementModal,
    closeStatementModal,
    currency,
  } = props;
  const isRevenue = statement?.type == 'revenue';
  const statusSx = {
    color: isRevenue ? theme.palette.add.main : theme.palette.danger.main,
  };
  const statementLabel = isRevenue ? 'Recebimento' : 'Pagamento';
  const StatementForm = isRevenue ? RevenueFormContainer : ExpenseFormContainer;

  return (
    <TableRow key={statement?.id} sx={tableBodyRowSx}>
      <StyledTableCell align="left">{statement?.id}</StyledTableCell>
      <StyledTableCell align="left" sx={tableBodyTitleSx}>
        {isRevenue
          ? getRevenueTitle(statement, statementCategory)
          : getExpenseTitle(statement, statementCategory)}
      </StyledTableCell>
      <StyledTableCell align="right" sx={statusSx}>
        {toCurrency(statement?.amount, currency?.code, 'none')}
      </StyledTableCell>
      {actions && (
        <StyledTableCell align="right">
          <IconButton onClick={onOpen}>
            <MoreVertIcon />
          </IconButton>
        </StyledTableCell>
      )}
      <Menu title="Ações" anchorEl={anchorEl} open={open} onClose={onClose}>
        <MenuItem onClick={onEdit}>Editar</MenuItem>
        <MenuItem onClick={onDelete}>Apagar</MenuItem>
      </Menu>
      <Modal
        title={`Edição de ${statementLabel}`}
        open={openStatementModal}
        onClose={closeStatementModal}
      >
        <StatementForm
          statement={statement}
          closeStatementModal={closeStatementModal}
          statementId={statement?.id}
        />
      </Modal>
    </TableRow>
  );
}
