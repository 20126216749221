import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { InputAdornment } from '@mui/material';
import { css } from 'glamor';
import React, { useCallback, useEffect, useState } from 'react';
import { makeDropDownOptions, toCurrency } from '../../../utils';
import Button from '../Button';
import Modal from '../Modal';
import Separator from '../Separator';
import theme, {
  buttonColors,
  spacing,
  uiColors,
  utilityColors,
} from '../theme';
import Typography from '../Typography';
import CurrencyInput from './CurrencyInput';
import DropDownInput from './DropDownInput';
import FieldBlock from './FieldBlock';
import Label from './Label';
import TextInput from './TextInput';
import Link from '../Link';
import { makeIsMobile } from '../../../utils/useScreenWidth';

const labelStyle = {
  marginBottom: spacing.xs,
  display: 'block',
};
const container = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: spacing.xxs,
  gap: spacing.s,
  height: 48,
  padding: spacing.s,
  '&:hover': {
    borderColor: theme.palette.border.hover,
  },
});
const mobileContainer = css(container, {
  height: 60,
});
const actions = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: spacing.m,
};
const list = css({
  marginTop: spacing.s,
  paddingTop: spacing.s,
  borderTop: `1px solid ${uiColors.border}`,
});
const listItem = css({
  display: 'flex',
  paddingLeft: spacing.m,
  paddingRight: spacing.m,
  marginBottom: spacing.s,
  paddingBottom: spacing.s,
  borderBottom: `1px solid ${uiColors.border}`,
});

const listItemDescription = {
  color: utilityColors.success,
  flex: 3,
};
const listItemAmount = {
  display: 'flex',
  justifyContent: 'flex-end',
  flex: 1,
  paddingLeft: spacing.s,
};
const listItemAction = {
  display: 'flex',
  justifyContent: 'center',
  flex: 0.5,
};
const iconStyle = {
  marginLeft: spacing.m,
  color: buttonColors.primary,
  cursor: 'pointer',
};
const currencyStyle = {
  fontSize: 12,
  alignItems: 'center',
  display: 'flex',
};
const totalAmountStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
};
const quantityStyle = {
  display: 'flex',
  marginRight: '5px',
};
const linkStyle = {
  display: 'flex',
  marginTop: spacing.xxs,
};

export default function MultipleItemsInput(props) {
  const {
    required,
    label,
    disabled,
    placeholder,
    searchInputName,
    searchInputPlaceholder = 'Selecione um item',
    data,
    fields,
    showQuantity = true,
    showAmount = false,
    units,
    style = {},
    editEnabled,
    hint,
    showUnits = true,
    costEnabled = false,
    priceEnabled = true,
    createForm,
    createLabel,
    currency,
  } = props;

  const CreateFormComponent = createForm;

  const initialValues = {
    quantity: showQuantity ? 1 : undefined,
    amount: showAmount ? 0.0 : undefined,
  };

  const [open, setOpen] = useState(false);
  const [isEditting, setIsEditting] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [removedIndex, setRemovedIndex] = useState(null);
  const [values, setValues] = useState(initialValues);
  const [deletedValue, setDeletedValue] = useState({});
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const isMobile = makeIsMobile();

  const onCreate = useCallback(() => setOpenCreateModal(true), []);
  const closeCreateModal = useCallback(() => setOpenCreateModal(false), []);

  const getDataItemByListItem = (listItem) => {
    return data?.find((item) => item?.id == listItem[`${searchInputName}`]);
  };

  const getUnitAbbreviation = (listItem) => {
    return units.find(
      (unit) => getDataItemByListItem(listItem)?.unitId == unit.id,
    )?.abbreviation;
  };

  const hideDestroyed = (item) => !!!item?._destroy;

  const serialize = (item, index) => {
    return { ...item, index, unitAbbreviation: getUnitAbbreviation(item) };
  };

  const items = fields.getAll()?.map(serialize)?.filter(hideDestroyed) || [];
  const selected = data?.filter(
    (item) => item.id === values?.[`${searchInputName}`],
  )[0];
  const totalCost = items
    .map((item) => {
      const dataItem = getDataItemByListItem(item);
      return (
        dataItem?.cost * (item?.quantity / (dataItem?.estimatedProduce || 1))
      );
    })
    .reduce((acc, num) => {
      return (acc += num);
    }, 0);
  const onOpen = useCallback(() => setOpen(true), []);

  const onClose = useCallback(() => {
    setOpen(false);
    setValues(initialValues);
    setIsEditting(false);
  }, []);

  const onChange = useCallback(
    (e) => {
      setValues({ ...values, [e.target.name]: e.target.value });
    },
    [values],
  );

  const onCurrencyChange = useCallback(
    (_, value) => {
      setValues({ ...values, amount: value });
    },
    [values],
  );

  const onAdd = useCallback(() => {
    !!removedIndex ? fields.insert(removedIndex, values) : fields.push(values);
    onClose();
  }, [fields, values, removedIndex]);

  const onEdit = useCallback(() => {
    fields.remove(currentIndex);
    fields.insert(currentIndex, values);
    onClose();
  }, [fields, currentIndex, values]);

  const onDelete = useCallback(
    (index) => () => {
      setDeletedValue({ ...fields.get(index), _destroy: 1 });
      fields.remove(index);
    },
    [fields],
  );

  const onClickEdit = useCallback(
    (item) => () => {
      setIsEditting(true);
      setOpen(true);
      setCurrentIndex(item.index);
      setValues({ ...values, ...item });
    },
    [],
  );

  useEffect(() => {
    if (deletedValue?.id) {
      fields.push(deletedValue);
      if (!removedIndex) {
        setRemovedIndex(fields?.length);
      }
      setDeletedValue({});
    }
  }, [fields, deletedValue, removedIndex]);

  useEffect(() => {
    if (!selected) return;
    setValues({ ...values, amount: selected?.amount });
  }, [selected]);

  const inputUnit = units.find((unit) => {
    return (
      unit.id ==
      data?.find((item) => item?.id == values[`${searchInputName}`])?.unitId
    );
  })?.abbreviation;

  const filteredData = data.filter((option) => {
    const ids = items.map((i) => i[`${searchInputName}`]);
    return !ids.includes(option.id);
  });

  const optionsForDropdown = isEditting ? data : filteredData;
  return (
    <>
      <FieldBlock style={style}>
        <Label style={labelStyle} required={required} disabled={disabled}>
          {label}
        </Label>
        <div className={isMobile ? mobileContainer : container}>
          <Typography>{placeholder}</Typography>
          <Button size="small" onClick={onOpen}>
            Selecionar
          </Button>
        </div>
      </FieldBlock>
      {createForm && (
        <Link
          style={linkStyle}
          onClick={onCreate}
          component={Typography}
          variant="link"
        >
          {createLabel || `Adicionar ${label.toLowerCase()}`}
        </Link>
      )}
      <div className={list}>
        {items.length ? (
          items.map((item, index) => {
            const id = item[`${searchInputName}`];
            const object = data.filter((i) => i.id === id)[0];
            return (
              <div key={`data-${id}-${index}`} className={listItem}>
                {!showUnits && (
                  <Typography style={quantityStyle}>
                    {`${item.quantity}x`}{' '}
                  </Typography>
                )}
                <Typography
                  sx={{ color: 'palette.success.dark' }}
                  style={listItemDescription}
                  weight="bold"
                >
                  {object?.name}
                </Typography>
                <Typography>
                  {showUnits ? item.quantity : ''}{' '}
                  {showUnits && item.unitAbbreviation
                    ? item.unitAbbreviation
                    : ''}
                </Typography>
                {item.amount && priceEnabled && (
                  <>
                    <div style={listItemAmount}>
                      <Typography style={currencyStyle}>
                        {toCurrency(item.amount, currency?.code)}
                      </Typography>
                    </div>
                    <div style={listItemAmount}>
                      <Typography weight="bold" style={currencyStyle}>
                        {toCurrency(
                          item.amount * item.quantity,
                          currency?.code,
                        )}
                      </Typography>
                    </div>
                  </>
                )}
                <div style={listItemAction}>
                  {editEnabled && !object?.isDeleted && (
                    <EditIcon
                      style={iconStyle}
                      onClick={onClickEdit(item, index)}
                    />
                  )}
                  <DeleteForeverIcon
                    style={iconStyle}
                    onClick={onDelete(index)}
                  />
                </div>
              </div>
            );
          })
        ) : (
          <div className={listItem}>
            <Typography sx={{ fontStyle: 'italic' }}>Lista vazia...</Typography>
          </div>
        )}
        {showAmount && (
          <>
            <div style={totalAmountStyle}>
              <Typography weight="bold" variant="h6">
                Total:
                {toCurrency(
                  items.reduce((a, b) => a + b.amount * b.quantity, 0),
                  currency?.code,
                )}
              </Typography>
            </div>
            <Separator />
          </>
        )}
        {costEnabled && (
          <div>
            Custo estimado:{' '}
            <strong style={{ color: 'black' }}>
              {toCurrency(totalCost, currency?.code)}{' '}
            </strong>
          </div>
        )}
        {hint}
      </div>
      {createForm && (
        <Modal title={label} open={openCreateModal} onClose={closeCreateModal}>
          <CreateFormComponent closeCreateModal={closeCreateModal} />
        </Modal>
      )}
      <Modal title={placeholder} open={open} onClose={onClose}>
        <FieldBlock>
          <DropDownInput
            name={searchInputName}
            onChange={onChange}
            data={makeDropDownOptions(
              optionsForDropdown,
              searchInputPlaceholder,
            )}
            value={values[`${searchInputName}`] || ''}
            disabled={isEditting}
            fluid
          />
        </FieldBlock>
        {showQuantity && (
          <FieldBlock>
            <TextInput
              name="quantity"
              onChange={onChange}
              value={values.quantity}
              label="Quantidade"
              type="number"
              disabled={!selected}
              required
              fluid
              InputProps={{
                endAdornment:
                  showUnits && inputUnit ? (
                    <InputAdornment position="end">{inputUnit}</InputAdornment>
                  ) : undefined,
              }}
            />
          </FieldBlock>
        )}
        {showAmount && (
          <>
            <FieldBlock>
              <CurrencyInput
                name="amount"
                onChange={onCurrencyChange}
                value={values.amount}
                label="Valor Unitário"
                disabled={!selected}
                required
                fluid
              />
            </FieldBlock>
            <Separator />
            <Typography weight="bold">
              Subtotal:{' '}
              <span weight="normal">
                {values.quantity && values.amount
                  ? toCurrency(values.quantity * values.amount, currency?.code)
                  : '--'}
              </span>
            </Typography>
          </>
        )}
        <div style={actions}>
          <Button onClick={isEditting ? onEdit : onAdd} disabled={!selected}>
            Adicionar
          </Button>
        </div>
      </Modal>
    </>
  );
}
