import React from 'react';
import EmptyState from '../library/EmptyState';
import AddButton from '../library/AddButton';
import ExpenseListItemContainer from './ExpenseListItemContainer';
import DefaultIcon from '../library/icons/DefaultIcon';

const wrapperSx = {
  display: 'flex',
  marginBottom: '16px',
};

export default function ExpensesList({ expenses, onAdd }) {
  return expenses.length ? (
    <>
      <div style={wrapperSx}>
        <AddButton onClick={onAdd} decrease wide text="Adicionar pagamento" />
      </div>
      {expenses.map((expense) => (
        <ExpenseListItemContainer key={expense.id} expense={expense} />
      ))}
    </>
  ) : (
    <EmptyState
      iconUrl={DefaultIcon}
      title="Nenhum pagamento cadastrado"
      cta="/statements/expenses/new"
      ctaLabel="Cadastrar pagamento"
    />
  );
}
