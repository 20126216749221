import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as form } from 'redux-form';
import usersReducer from './usersReducer';
import currentUserReducer from './currentUserReducer';
import notifier from './notifierReducer';
import configsReducer from './configsReducer';
import customersReducer from './customersReducer';
import suppliesReducer from './suppliesReducer';
import recipesReducer from './recipesReducer';
import productsReducer from './productsReducer';
import statementsReducer from './statementsReducer';
import inventoriesReducer from './inventoriesReducer';
import ordersReducer from './ordersReducer';
import productsSuppliesReducer from './productsSuppliesReducer';
import productsRecipesReducer from './productsRecipesReducer';
import recipesSuppliesReducer from './recipesSuppliesReducer';
import ordersProductsReducer from './ordersProductsReducer';
import ordersItemsReducer from './ordersItemsReducer';
import tasksReducer from './tasksReducer';
import notificationsReducer from './notificationsReducer';
import notificationsListReducer from './notificationsListReducer';
import suppliesListReducer from './suppliesListReducer';
import recipesListReducer from './recipesListReducer';
import productsListReducer from './productsListReducer';
import inventoriesListReducer from './inventoriesListReducer';
import customersListReducer from './customersListReducer';
import ordersListReducer from './ordersListReducer';
import statementsListReducer from './statementsListReducer';
import pricingReducer from './pricingReducer';
import backgroundTasksReducer from './backgroundTasksReducer';
import bannersReducer from './bannersReducer';
import tasksListReducer from './tasksListReducer';
import productsBundlesReducer from './productsBundlesReducer';
import recipesConnectionsReducer from './recipesConnectionsReducer';

export default (history) =>
  combineReducers({
    currentUserId: currentUserReducer,
    customersList: customersListReducer,
    entities: combineReducers({
      configs: configsReducer,
      users: usersReducer,
      tasks: tasksReducer,
      customers: customersReducer,
      products: productsReducer,
      recipes: recipesReducer,
      supplies: suppliesReducer,
      statements: statementsReducer,
      inventories: inventoriesReducer,
      orders: ordersReducer,
      productsSupplies: productsSuppliesReducer,
      productsRecipes: productsRecipesReducer,
      productsBundles: productsBundlesReducer,
      recipesSupplies: recipesSuppliesReducer,
      recipesConnections: recipesConnectionsReducer,
      ordersProducts: ordersProductsReducer,
      ordersItems: ordersItemsReducer,
      notifications: notificationsReducer,
      backgroundTasks: backgroundTasksReducer,
      banners: bannersReducer,
    }),
    form,
    inventoriesList: inventoriesListReducer,
    notifier,
    notificationsList: notificationsListReducer,
    ordersList: ordersListReducer,
    statementsList: statementsListReducer,
    pricing: pricingReducer,
    productsList: productsListReducer,
    recipesList: recipesListReducer,
    router: connectRouter(history),
    suppliesList: suppliesListReducer,
    tasksList: tasksListReducer,
  });
