import React, { useCallback, useEffect } from 'react';
import { replace } from 'connected-react-router';
import { connect } from 'react-redux';
import { fetchCustomers } from '../../processes/customerProcessess';
import { getActiveCustomersByFetchedIds } from '../../selectors/customerSelectors';
import CustomersPage from './CustomersPage';
import { formValueSelector } from 'redux-form';
import { getCurrencyById } from '../../selectors/configSelectors';
import { getCurrentUserCompany } from '../../selectors/userSelectors';

const FORM_NAME = 'customerSearch';

function CustomersPageContainer(props) {
  const { dispatch } = props;
  useEffect(() => {
    fetchCustomers(dispatch, { withAmountSpent: true });
  }, [dispatch]);

  const onAdd = useCallback(() => {
    dispatch(replace('/customers/new'));
  }, [dispatch]);

  return <CustomersPage onAdd={onAdd} {...props} />;
}

function mapStateToProps(state) {
  const formSelector = formValueSelector(FORM_NAME);
  const nameCont = formSelector(state, 'nameCont');
  const company = getCurrentUserCompany(state);
  const currency = getCurrencyById(state, company?.currencyId);

  return {
    customers: getActiveCustomersByFetchedIds(state),
    currency,
    nameCont,
  };
}

export default connect(mapStateToProps)(CustomersPageContainer);
