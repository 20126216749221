import React from 'react';
import Box from '../library/Box';
import Spinner from '../library/Spinner';
import Title from '../library/Title';
import Page from '../page/Page';
import RevenuesList from './RevenuesList';
import StatementSearchFormContainer from './StatementSearchFormContainer';

const loadingSx = {
  textAlign: 'center',
};

export default function RevenuesPage({ revenues, onAdd, loading }) {
  return (
    <Page>
      <Title>Recebimentos</Title>
      <StatementSearchFormContainer form="revenueSearch" typeEq="revenue" />
      {loading ? (
        <Box pt={6} sx={loadingSx}>
          <Spinner inline />
        </Box>
      ) : (
        <RevenuesList revenues={revenues} onAdd={onAdd} />
      )}
    </Page>
  );
}
