import React from 'react';
import { toCurrency } from '../../utils';
import { Box, Separator } from '../library';
import { Card } from '../library/cards';
import EmptyState from '../library/EmptyState';
import HorizontalItems from '../library/HorizontalItems';
import SupplyIcon from '../library/images/supply.png';
import Modal from '../library/Modal';
import Page from '../page/Page';
import InventoryFormContainer from './InventoryFormContainer';
import InventoryListItemContainer from './InventoryListItemContainer';
import InventorySearchFormContainer from './InventorySearchFormContainer';
import Pagination from '../library/Pagination';

export default function InventoriesPage({
  inventories,
  supply,
  unit,
  onAdd,
  openInventoryModal,
  closeInventoryModal,
  loading,
  currentPage,
  totalPages,
  onPageChange,
  isMobile,
  currency,
}) {
  return (
    <Page
      back="/catalogs/supplies"
      title={`Estoques - ${supply?.name}`}
      headerType="secondary"
    >
      <InventorySearchFormContainer supplyId={supply?.id} onAddButton={onAdd} />
      {!loading && inventories?.length > 0 ? (
        <>
          <Card title={supply?.name}>
            <Box pt={2}>
              <HorizontalItems
                items={[
                  {
                    title: 'Preço médio',
                    description: `${toCurrency(supply?.cost, currency?.code)}`,
                  },
                  {
                    title: 'Quantidade em estoque',
                    description: `${+supply?.inStock?.toFixed(
                      2,
                    )} ${unit?.abbreviation.toLowerCase()}`,
                  },
                ]}
              />
            </Box>
          </Card>
          <Separator />
          {inventories.map((inventory) => (
            <InventoryListItemContainer
              key={inventory.id}
              inventory={inventory}
              supply={supply}
              currency={currency}
            />
          ))}
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
            short={isMobile}
          />
        </>
      ) : (
        <EmptyState
          iconUrl={SupplyIcon}
          loading={loading}
          title="Nenhum estoque cadastrado"
        />
      )}

      <Modal
        title="Gerenciamento de Estoque"
        open={openInventoryModal}
        onClose={closeInventoryModal}
      >
        <InventoryFormContainer
          supplyId={supply?.id}
          closeInventoryModal={closeInventoryModal}
          currency={currency}
        />
      </Modal>
    </Page>
  );
}
