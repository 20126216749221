const getConfigs = (action) => action.entities;

const initialState = {
  productCategories: {},
  recipeCategories: {},
  supplyCategories: {},
  strategicRoles: {},
  expenseCategories: {},
  expenseTypes: {},
  paymentMethods: {},
  units: {},
  orderReasons: {},
  countries: {},
  currencies: {},
  states: {},
  companyTimes: {},
  levels: {},
  companyFlagshipProducts: {},
  companyLocationTypes: {},
  statementCategories: {},
  productCompetitions: {},
  investmentCapitals: {},
  currentRevenues: {},
  customerTypes: {},
  importTemplateUrl: '',
  budgetUrl: '',
  revenueCategoryId: '',
  expenseCategoryId: '',
  defaultCountryId: '',
  defaultCurrencyId: '',
};

export default function configsReducer(state = initialState, action) {
  switch (action.type) {
    case 'CONFIGS_FETCHED':
      return {
        ...state,
        ...getConfigs(action),
        budgetUrl: action.budgetUrl,
        importTemplateUrl: action.importTemplateUrl,
        revenueCategoryId: action.revenueCategoryId,
        expenseCategoryId: action.expenseCategoryId,
        defaultCountryId: action.defaultCountryId,
        defaultCurrencyId: action.defaultCurrencyId,
      };
    default:
      return state;
  }
}
