import React from 'react';
import EmptyState from '../library/EmptyState';
import Page from '../page/Page';
import CustomerListItemContainer from './CustomerListItemContainer';
import DefaultIcon from '../library/icons/DefaultIcon';
import CustomerSearchFormContainer from './CustomerSearchFormContainer';

export default function CustomersPage({ customers, onAdd, currency }) {
  return (
    <Page back="/" title="Clientes" headerType="secondary">
      <CustomerSearchFormContainer onAddButton={onAdd} />
      {customers?.length ? (
        <>
          {customers.map((customer) => (
            <CustomerListItemContainer
              key={customer.id}
              customer={customer}
              currency={currency}
            />
          ))}
        </>
      ) : (
        <EmptyState
          iconUrl={DefaultIcon}
          title="Nenhum cliente cadastrado"
          cta="/customers/new"
          ctaLabel="Cadastrar cliente"
        />
      )}
    </Page>
  );
}
