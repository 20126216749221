import React, { useCallback, useState } from 'react';
import {
  ImageField,
  DropDownField,
  RadioField,
  TextField,
  MultipleItemsField,
  DateTimeField,
  SearchDropDownField,
} from '../library/forms';
import { Grid, Typography, Box, Separator, Modal, Button } from '../library';
import { currencyMask, makeDropDownOptions, percentageMask } from '../../utils';
import makeSimpleDropDownOptions from '../../utils/makeSimpleDropDownOptions';
import theme, { decorationColors, spacing } from '../library/theme';
import { css } from 'glamor';
import ProductFormContainer from '../products/ProductFormContainer';
import OrderStatements from '../statements/OrderStatements';
import SimpleMultipleItemsField from '../library/forms/SimpleMultipleItemsField';
import Accordion from '../library/forms/Accordion';

const imageWrapperSx = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: spacing.s,
};
const imageSx = {
  width: 100,
};
const formTitleSx = {
  paddingBottom: spacing.s,
};
const deliveryTimeSx = {
  marginBottom: spacing.s,
};
const clientSx = {
  mb: 1,
};
const labelClass = css({
  fontSize: 12,
  fontWeight: 'bold',
  margin: `${spacing.m} auto`,
  color: theme.palette.primary.main,
  cursor: 'pointer',
});

const validateDiscountinPercentage = (discount) => {
  if (!!discount && (discount < 0 || discount > 100)) return 'minValue';
  else return undefined;
};

export default function OrderFormFields(props) {
  const {
    customers,
    customer,
    products,
    orderReasons,
    status,
    deliveryType,
    paymentStatus,
    paymentMethods,
    order,
    orderId,
    orderType,
    paymentMethodId,
    statements,
    currency,
    amount,
    discount,
    discountType,
    updateDiscountPercentage,
    updateDiscountAmount,
  } = props;

  const onModal = !!props?.closeCreateModal;
  const [openCustomerSelection, setOpenCustomerSelection] = useState(false);
  const onCustomerSelect = useCallback(
    () => setOpenCustomerSelection(true),
    [],
  );
  const closeCustomerSelect = useCallback(
    () => setOpenCustomerSelection(false),
    [],
  );
  const currencySymbol = currency?.symbol ? `(${currency?.symbol})` : '(R$)';
  const defaultWrapperProps = {
    sx: {
      width: '100%',
    },
    defaultExpanded: order || orderId,
  };
  const validateDiscountinAmount = useCallback(
    (discountAmount) => {
      if (
        !!discountAmount &&
        (discountAmount < 0 || discountAmount > amount / (1 - discount / 100))
      )
        return 'minValue';
      else return undefined;
    },
    [amount, discount],
  );

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {!!orderType && (
            <Accordion parentProps={defaultWrapperProps} title="Produtos">
              {orderType == 'standard' && (
                <MultipleItemsField
                  name="ordersProductsAttributes"
                  data={products}
                  label="Escolha o produto"
                  placeholder="Selecione um produto existente"
                  searchInputPlaceholder="Selecione um produto existente"
                  searchInputName="productId"
                  required
                  editEnabled
                  showAmount
                  createForm={!onModal && ProductFormContainer}
                  createLabel={!onModal && 'Criar novo produto'}
                  currency={currency}
                />
              )}
              {orderType == 'quickSell' && (
                <Box>
                  <SimpleMultipleItemsField
                    name="ordersItemsAttributes"
                    currency={currency}
                  />
                </Box>
              )}
            </Accordion>
          )}
        </Grid>
        <Grid item xs={12}>
          <Accordion
            parentProps={defaultWrapperProps}
            title="Cliente"
            hint={!!customer && `Cliente associado: ${customer?.name}`}
          >
            <Button
              variant="outlined"
              onClick={onCustomerSelect}
              sx={{
                border: `0.5px solid ${decorationColors.decorationBrand}`,
                width: '100%',
              }}
            >
              Associar cliente
            </Button>
            <Separator />
            <TextField
              name="buyerName"
              label="Nome do comprador"
              disabled={!!customer}
              fluid
            />
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <Accordion
            parentProps={defaultWrapperProps}
            title="Informações de entrega"
          >
            <Grid container columnSpacing={3}>
              <Grid item xs={12} sx={deliveryTimeSx}>
                <DateTimeField
                  name="scheduleAt"
                  label="Data de entrega"
                  required
                  fluid
                />
              </Grid>
              <Grid item xs={12}>
                <RadioField
                  name="deliveryType"
                  value="takeaway"
                  label="Cliente vai retirar"
                />
              </Grid>
              <Grid item xs={12}>
                <RadioField
                  name="deliveryType"
                  value="delivery"
                  label="Faremos a entrega"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="deliveryFee"
                  placeholder={`Taxa de entrega ${currencySymbol}`}
                  {...currencyMask(currency?.symbol)}
                  disabled={deliveryType != 'delivery'}
                  fluid
                />
              </Grid>
              <Grid item xs={12} sx={{ marginTop: spacing.m }}>
                <DropDownField
                  name="status"
                  label="Status do pedido"
                  data={makeSimpleDropDownOptions(
                    status,
                    'Selecione uma opção',
                  )}
                  required
                  fluid
                />
              </Grid>
            </Grid>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <Accordion parentProps={defaultWrapperProps} title="Pagamento">
            <Grid container>
              <Grid item xs={12}>
                <RadioField name="paymentStatus" value="paid" label="Pago" />
              </Grid>
              <Grid item xs={12}>
                <RadioField
                  name="paymentStatus"
                  value="unpaid"
                  label="Não pago"
                />
                <Separator />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <DropDownField
                  name="paymentMethodId"
                  label="Método de pagamento"
                  data={makeSimpleDropDownOptions(
                    paymentMethods,
                    'Selecione uma opção',
                  )}
                  fluid
                />
                {paymentMethodId == 6 && (
                  <TextField
                    name="customPaymentMethod"
                    label="Método de pagamento alternativo"
                    rows={2}
                    multiline
                    fluid
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="amountPaidInitially"
                  label="Valor pago inicialmente"
                  placeholder={`Valor pago ${currencySymbol}`}
                  {...currencyMask(currency?.symbol)}
                  disabled={paymentStatus != 'unpaid'}
                  fluid
                />
              </Grid>
              <Grid item xs={12}>
                <Separator />
                <Typography
                  weight="bold"
                  sx={{
                    color: '#000000DE',
                    marginBottom: `${spacing.xs} !important`,
                  }}
                >
                  Desconto
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <RadioField
                  name="discountType"
                  value="percentage"
                  label="Descontar por porcentagem"
                />
              </Grid>
              <Grid item xs={12}>
                <RadioField
                  name="discountType"
                  value="amount"
                  label="Descontar por subtração"
                />
                <Separator />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  name="discount"
                  label="Desconto em %"
                  placeholder="Desconto (%)"
                  validate={validateDiscountinPercentage}
                  onBlur={updateDiscountAmount}
                  fluid
                  disabled={discountType != 'percentage'}
                  {...percentageMask}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="discountAmount"
                  label="Desconto em valor"
                  placeholder="Desconto ($)"
                  validate={validateDiscountinAmount}
                  onBlur={updateDiscountPercentage}
                  fluid
                  disabled={discountType != 'amount'}
                  {...currencyMask(currency?.symbol)}
                />
              </Grid>
            </Grid>
            {order && (
              <>
                <Separator />
                <Box sx={formTitleSx}>
                  <Typography variant="formTitle">Financeiro</Typography>
                </Box>
                <OrderStatements
                  statements={statements}
                  orderId={order?.id}
                  currency={currency}
                />
              </>
            )}
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <Accordion
            parentProps={defaultWrapperProps}
            title="Informações adicionais"
          >
            <SearchDropDownField
              name="orderReasonId"
              label="Motivo do pedido (opcional)"
              options={makeDropDownOptions(orderReasons, 'Selecione uma opção')}
              fluid
            />
            <Separator />
            <Box sx={imageWrapperSx}>
              <Box sx={imageSx}>
                <ImageField name="imageUrl" />
              </Box>
              <Box>
                <label htmlFor={'imageUrl'} className={labelClass}>
                  Adicionar imagem
                </label>
              </Box>
            </Box>
            <Separator />
            <TextField
              name="notes"
              label="Observação (opcional)"
              rows={3}
              multiline
              fluid
              hint={
                'As observações do pedido serão exibidas para o cliente ao compartilhar no WhatsApp.'
              }
            />
          </Accordion>
        </Grid>
      </Grid>

      <Modal
        title="Selecionar um cliente"
        open={openCustomerSelection}
        onClose={closeCustomerSelect}
      >
        <SearchDropDownField
          name="customerId"
          label="Cliente"
          options={makeDropDownOptions(customers, 'Selecione um cliente')}
          fluid
          sx={clientSx}
        />
      </Modal>
    </>
  );
}
