import { normalize, schema } from 'normalizr';
import { apiDel, apiGet, apiPost, apiPut } from './helpers/api';
import { replace } from 'connected-react-router';
import toQueryString from '../utils/toQueryString';
import { notifySubmitSucceeded } from './notifierProcesses';

export const customerSchema = new schema.Entity('customers');

export const fetchCustomers = (dispatch, props = {}) => {
  const { withAmountSpent = false, withAmountSpentInPeriod } = props;
  const filters = withAmountSpentInPeriod
    ? { withAmountSpentInPeriod }
    : { withAmountSpent };
  const filterParams = toQueryString(filters);

  return apiGet(`/api/v1/customers?${filterParams}`).then((response) => {
    dispatch({
      type: 'CUSTOMERS_FETCHED',
      ...normalize(response.body.data, new schema.Array(customerSchema)),
    });
  });
};

export const fetchCustomer = (dispatch, id) => {
  return apiGet(`/api/v1/customers/${id}`).then((response) => {
    dispatch({
      type: 'CUSTOMER_FETCHED',
      ...normalize(response.body.data, customerSchema),
    });
  });
};

export const createOrUpdateCustomer = (values, dispatch, props) => {
  const handler = !values.id ? createCustomer : updateCustomer;
  return handler(values, dispatch, props);
};

const createCustomer = (values, dispatch, props) => {
  const onModal = !!props?.closeCreateModal;

  return apiPost('/api/v1/customers')
    .send({ customer: values })
    .then((response) => {
      dispatch({
        type: 'CUSTOMER_CREATED',
        ...normalize(response.body.data, customerSchema),
      });
      onModal ? props?.closeCreateModal() : dispatch(replace('/customers'));
      notifySubmitSucceeded(dispatch, 'success', 'Cliente adicionado!');
    });
};

const updateCustomer = (values, dispatch, props) => {
  return apiPut(`/api/v1/customers/${props.customerId}`)
    .send({ customer: values })
    .then((response) => {
      dispatch({
        type: 'CUSTOMER_UPDATED',
        ...normalize(response.body.data, new schema.Array(customerSchema)),
      });
      dispatch(replace('/customers'));
      notifySubmitSucceeded(dispatch, 'success', 'Cliente atualizado!');
    });
};

export const deleteCustomer = (dispatch, id) =>
  apiDel(`/api/v1/customers/${id}`).then(() => {
    dispatch({
      type: 'CUSTOMER_DELETED',
      id,
    });
  });

export const submitSearchFilter = (values, dispatch) => {
  dispatch({ type: 'CUSTOMER_SEARCH_FETCH_REQUEST' });
  const searchParams = toQueryString(values);
  apiGet(`/api/v1/customers?${searchParams}`).then((response) => {
    dispatch({
      type: 'CUSTOMER_SEARCH_FETCHED',
      ...normalize(response.body.data, new schema.Array(customerSchema)),
    });
  });
};

export const sendMessage = (customer) => {
  const messageText = `Olá, ${customer?.name}!`;
  const url = `https://wa.me/${customer?.phoneCountryCode || '55'}${
    customer?.phone
  }?text=${encodeURIComponent(messageText)}`;
  return window.open(url, '_blank').focus;
};
