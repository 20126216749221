import React from 'react';
import {
  AccordionDetails,
  AccordionSummary,
  Accordion as CustomAccordion,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { spacing } from '../theme';
import Typography from '../Typography';

const summarySx = {
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.xxs,
};

export default function Accordion({
  title,
  hint,
  children,
  parentProps,
  summaryProps,
  detailsProps,
}) {
  return (
    <CustomAccordion {...parentProps}>
      <AccordionSummary {...summaryProps} expandIcon={<ArrowDropDownIcon />}>
        <div style={summarySx}>
          {title}
          {hint && <Typography variant="caption">{hint}</Typography>}
        </div>
      </AccordionSummary>
      <AccordionDetails {...detailsProps}>{children}</AccordionDetails>
    </CustomAccordion>
  );
}
