import React from 'react';
import ExpensesList from './ExpensesList';
import Title from '../library/Title';
import Page from '../page/Page';
import Box from '../library/Box';
import Spinner from '../library/Spinner';
import StatementSearchFormContainer from './StatementSearchFormContainer';

const loadingSx = {
  textAlign: 'center',
};

export default function ExpensesPage({ expenses, onAdd, loading }) {
  return (
    <Page>
      <Title>Pagamentos</Title>
      <StatementSearchFormContainer form="expenseSearch" typeEq="expense" />
      {loading ? (
        <Box pt={6} sx={loadingSx}>
          <Spinner inline />
        </Box>
      ) : (
        <ExpensesList expenses={expenses} onAdd={onAdd} />
      )}
    </Page>
  );
}
