import React, { useCallback, useEffect } from 'react';
import { replace } from 'connected-react-router';
import { connect } from 'react-redux';
import {
  getFetchedStatementsByType,
  getStatementPageInfo,
} from '../../selectors/statementSelectors';
import ExpensesPage from './ExpensesPage';
import { fetchStatements } from '../../processes/statementProcesses';

function ExpensesPageContainer(props) {
  const { dispatch, filters } = props;

  useEffect(() => {
    filters?.typeEq == 'expense' && fetchStatements(dispatch, { filters });
  }, [dispatch, filters]);

  const onAdd = useCallback(() => {
    dispatch(replace('/statements/expenses/new'));
  }, [dispatch]);

  return <ExpensesPage onAdd={onAdd} {...props} />;
}

function mapStateToProps(state) {
  const { filters, loading } = getStatementPageInfo(state);

  return {
    expenses: getFetchedStatementsByType(state, 'expense'),
    loading,
    filters,
  };
}

export default connect(mapStateToProps)(ExpensesPageContainer);
