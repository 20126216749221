import React, { useCallback, useEffect } from 'react';
import { replace } from 'connected-react-router';
import { connect } from 'react-redux';
import { fetchOrders } from '../../processes/orderProcesses';
import OrdersPage from './OrdersPage';
import {
  getOrderPageInfo,
  getOrdersByFetchedIds,
} from '../../selectors/orderSelectors';
import { formValueSelector } from 'redux-form';
import { getCurrentUserCompany } from '../../selectors/userSelectors';
import { getCurrencyById } from '../../selectors/configSelectors';
import { makeIsMobile } from '../../utils/useScreenWidth';

const FORM_NAME = 'orderSearch';

function OrdersPageContainer(props) {
  const { dispatch, currentPage, filters } = props;
  const isMobile = makeIsMobile();

  useEffect(() => {
    if (!filters?.betweenDates?.length) return;

    fetchOrders(dispatch, { currentPage, filters, paged: true });
  }, [dispatch, currentPage, filters]);

  const onAdd = useCallback(() => {
    dispatch(replace('/orders/new'));
  }, [dispatch]);

  const pageChangeHandler = useCallback(
    (_, currentPage = 1) => {
      dispatch({
        type: 'ORDERS_PAGE_CHANGED',
        currentPage,
      });
    },
    [dispatch],
  );

  return (
    <OrdersPage
      onAdd={onAdd}
      onPageChange={pageChangeHandler}
      isMobile={isMobile}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  const formSelector = formValueSelector(FORM_NAME);
  const { scheduledAtFrom, scheduledAtTo } = formSelector(
    state,
    'scheduledAtFrom',
    'scheduledAtTo',
  );
  const betweenDates = [scheduledAtFrom, scheduledAtTo];
  const company = getCurrentUserCompany(state);
  const currency = getCurrencyById(state, company?.currencyId);
  const { currentPage, totalPages, totalCount, filters, loading } =
    getOrderPageInfo(state);

  return {
    orders: getOrdersByFetchedIds(state),
    loading,
    currentPage,
    totalPages,
    totalCount,
    filters,
    scheduledAtFrom,
    scheduledAtTo,
    betweenDates,
    currency,
  };
}

export default connect(mapStateToProps)(OrdersPageContainer);
