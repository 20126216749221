import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import {
  createOrUpdateExpense,
  fetchStatement,
} from '../../processes/statementProcesses';
import {
  getConfig,
  getCurrencyById,
  getStatementCategoriesByType,
} from '../../selectors/configSelectors';
import { getStatement } from '../../selectors/statementSelectors';
import ExpenseForm from './ExpenseForm';
import { getCurrentUserCompany } from '../../selectors/userSelectors';

const FORM_NAME = 'expense';

function ExpenseFormContainer(props) {
  const { dispatch, statementId, statement } = props;

  useEffect(() => {
    if (statement || !statementId) return;
    fetchStatement(dispatch, statementId);
  }, [statement, statementId]);

  return <ExpenseForm {...props} />;
}

function mapStateToProps(state, ownProps) {
  const statementId =
    ownProps?.statementId || ownProps?.match?.params?.statementId;
  const statement = ownProps?.statement || getStatement(state, statementId);
  const isModal = !!ownProps?.closeStatementModal;
  const company = getCurrentUserCompany(state);
  const currency = getCurrencyById(state, company?.currencyId);
  const formSelector = formValueSelector(FORM_NAME);
  const paymentMethodId = formSelector(state, 'paymentMethodId');
  const installmentPaymentMethods = [2, 4, 5];

  return {
    initialValues: {
      orderId: ownProps?.orderId,
      ...statement,
      type: 'expense',
      orderStatement: isModal && 'true',
      installments: statement?.installments || 1,
    },
    statementId,
    statement,
    statementCategories: getStatementCategoriesByType(state, 'expense'),
    paymentMethods: getConfig(state, 'paymentMethods'),
    currency,
    paymentMethodId,
    installmentPaymentMethods,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'expense',
    onSubmit: createOrUpdateExpense,
    enableReinitialize: true,
  })(ExpenseFormContainer),
);
