import { createSelector } from 'reselect';
import orderByDate from '../utils/orderByDate';
import { getOrdersProducts } from './ordersProductSelectors';
import { getOrdersItems } from './ordersItemSelectors';

const getOrders = (state) => state.entities.orders;
const getId = (_, id) => id;
const getOrdersList = (state) => state.ordersList;
const getFetchedIds = (state) => state.ordersList.orderIds;

export const getOrderPageInfo = createSelector(getOrdersList, (ordersList) => ({
  currentPage: ordersList.currentPage,
  totalPages: ordersList.totalPages,
  totalCount: ordersList.totalCount,
  filters: ordersList.filters,
  loading: ordersList.loading,
}));

export const getAllOrders = createSelector(getOrders, (orders) =>
  Object.values(orders).sort(orderByDate('scheduleAt')),
);

export const getOrder = createSelector(
  getOrders,
  getId,
  (orders, id) => orders[id],
);

export const getOrderByUuid = createSelector(getOrders, getId, (orders, id) => {
  return Object.values(orders).filter((order) => order.uuid == id)[0];
});

export const getOrderProductsPreview = createSelector(
  getOrders,
  getId,
  getOrdersProducts,
  (orders, id, orderProducts) => {
    const order = orders[id];

    return order?.meta?.ordersProducts?.map((orderProductId) => {
      const orderProduct = orderProducts[orderProductId];
      const product = order.meta.products.find(
        (prod) => prod.id == orderProduct?.productId,
      );

      return {
        title: `${orderProduct?.quantity}x`,
        description: product?.name,
      };
    });
  },
);

export const getOrderItemsPreview = createSelector(
  getOrders,
  getId,
  getOrdersItems,
  (orders, id, orderItems) => {
    const order = orders[id];

    return order?.meta?.ordersItems?.map((orderItemId) => {
      const orderItem = orderItems[orderItemId];
      return {
        title: '1x',
        description: orderItem?.name,
      };
    });
  },
);

export const getOrdersByFetchedIds = createSelector(
  getAllOrders,
  getFetchedIds,
  (orders, ids) =>
    orders
      .filter((order) => ids.includes(order.id))
      .sort(orderByDate('scheduleAt')),
);
