import { normalize, schema } from 'normalizr';
import { apiGet } from './helpers/api';

const phoneCountryCodes = new schema.Entity('phoneCountryCodes');
const productCategories = new schema.Entity('productCategories');
const recipeCategories = new schema.Entity('recipeCategories');
const supplyCategories = new schema.Entity('supplyCategories');
const strategicRoles = new schema.Entity('strategicRoles');
const paymentMethods = new schema.Entity('paymentMethods');
const units = new schema.Entity('units');
const orderReasons = new schema.Entity('orderReasons');
const countries = new schema.Entity('countries');
const currencies = new schema.Entity('currencies');
const states = new schema.Entity('states');
const companyTimes = new schema.Entity('companyTimes');
const levels = new schema.Entity('levels');
const companyFlagshipProducts = new schema.Entity('companyFlagshipProducts');
const companyLocationTypes = new schema.Entity('companyLocationTypes');
const statementCategories = new schema.Entity('statementCategories');
const productCompetitions = new schema.Entity('productCompetitions');
const investmentCapitals = new schema.Entity('investmentCapitals');
const currentRevenues = new schema.Entity('currentRevenues');
const customerTypes = new schema.Entity('customerTypes');

const valuesSchema = new schema.Values(
  {
    phoneCountryCodes: [phoneCountryCodes],
    productCategories: [productCategories],
    recipeCategories: [recipeCategories],
    supplyCategories: [supplyCategories],
    strategicRoles: [strategicRoles],
    paymentMethods: [paymentMethods],
    units: [units],
    orderReasons: [orderReasons],
    countries: [countries],
    currencies: [currencies],
    states: [states],
    companyTimes: [companyTimes],
    levels: [levels],
    companyFlagshipProducts: [companyFlagshipProducts],
    companyLocationTypes: [companyLocationTypes],
    statementCategories: [statementCategories],
    productCompetitions: [productCompetitions],
    investmentCapitals: [investmentCapitals],
    currentRevenues: [currentRevenues],
    customerTypes: [customerTypes],
  },
  (_input, _parent, key) => `${key}`,
);

export const fetchConfigs = (dispatch) => {
  return apiGet('/api/v1/config').then((response) => {
    const data = response.body.data[0];
    dispatch({
      type: 'CONFIGS_FETCHED',
      ...normalize(data, valuesSchema),
      importTemplateUrl: data.importTemplateUrl,
      budgetUrl: data.budgetUrl,
      revenueCategoryId: data.revenueCategoryId,
      expenseCategoryId: data.expenseCategoryId,
      defaultCountryId: data.defaultCountryId,
    });
  });
};
