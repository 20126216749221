export const notifySuccess = (options) => ({
  type: 'NOTIFIER_REQUESTED',
  payload: {
    type: 'success',
    variant: 'filled',
    hideDuration: 3000,
    message: options.message,
  },
});

export const notifyError = (options) => ({
  type: 'NOTIFIER_REQUESTED',
  payload: {
    type: 'error',
    variant: 'filled',
    hideDuration: 5000,
    message: options.message,
  },
});

export const notifyWarning = (options) => ({
  type: 'NOTIFIER_REQUESTED',
  payload: {
    type: 'warning',
    variant: 'filled',
    hideDuration: 3000,
    message: options.message,
  },
});

export const notifySubmitSucceeded = (dispatch, type, message) => {
  dispatch(notifySuccess({ type, message }));
};

export const dismissNotifier = () => ({
  type: 'NOTIFIER_DISMISS_REQUESTED',
});
