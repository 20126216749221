import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { change, formValueSelector } from 'redux-form';
import buildForm from '../library/forms/buildForm';
import PricingCalculatorForm from './PricingCalculatorForm';
import { getCurrentUser } from '../../selectors/userSelectors';
import {
  getAllProducts,
  getProduct,
  getProductTotalCost,
} from '../../selectors/productSelectors';
import {
  getConfig,
  getConfigById,
  getCurrencyById,
  getStrategicRoleById,
} from '../../selectors/configSelectors';
import { fetchProducts } from '../../processes/productProcessess';
import { calculateProductPricing } from '../../processes/pricingProcesses';
import { getPricingCalculationResult } from '../../selectors/pricingSelector';
import { fetchRecipes } from '../../processes/recipeProcessess';
import { fetchSupplies } from '../../processes/supplyProcessess';

const FORM_NAME = 'pricingCalculator';

const form = buildForm(FORM_NAME);

function PricingCalculatorFormContainer(props) {
  const {
    dispatch,
    product,
    productCategory,
    currentUser,
    pricingResult,
    productTotalCost,
    finalProductCost,
    finalProductPrice,
    averageCost,
    averagePrice,
    averageProfitMargin,
    strategicRole,
    submitSucceeded,
  } = props;

  const [resultData, setResultData] = useState(null);

  useEffect(() => {
    fetchProducts(dispatch, { filters: { isDeletedEq: false } });
    fetchRecipes(dispatch);
    fetchSupplies(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (averageCost > 0 && averagePrice > 0) {
      const averageMargin = +(
        ((averagePrice - averageCost) / averageCost) *
        100
      ).toFixed(2);
      dispatch(change(FORM_NAME, 'averageProfitMargin', averageMargin));
    }
  }, [dispatch, averageCost, averagePrice]);

  useEffect(() => {
    if (product) {
      dispatch(
        change(
          FORM_NAME,
          'productPreparationTime',
          product?.preparationTime || product?.productionTime,
        ),
      );
      dispatch(
        change(FORM_NAME, 'productCategoryId', product?.productCategoryId),
      );
      dispatch(change(FORM_NAME, 'strategicRoleId', product?.strategicRoleId));
      dispatch(change(FORM_NAME, 'productCost', productTotalCost));
    }
  }, [dispatch, product]);

  useEffect(() => {
    if (pricingResult) {
      const newResultData = {
        result: pricingResult,
        cost: finalProductCost,
        price: finalProductPrice,
        averageCost: averageCost,
        averagePrice: averagePrice,
        averageProfitMargin: averageProfitMargin,
        strategicRole: strategicRole?.name,
      };
      setResultData(newResultData);
    }
  }, [pricingResult, submitSucceeded]);

  useEffect(() => {
    const companyLocation = currentUser?.companiesAttributes?.[0]?.location;
    const averages = productCategory?.averages[companyLocation];

    if (productCategory) {
      dispatch(change(FORM_NAME, 'averageCost', averages?.average_cost));
      dispatch(change(FORM_NAME, 'averagePrice', averages?.average_price));
      dispatch(
        change(
          FORM_NAME,
          'averageProfitMargin',
          averages?.average_profit_margin,
        ),
      );
    }
  }, [dispatch, productCategory]);

  return <PricingCalculatorForm resultData={resultData} {...props} />;
}

function mapStateToProps(state) {
  const currentUser = getCurrentUser(state);
  const products = getAllProducts(state);
  const productCategories = getConfig(state, 'productCategories');
  const strategicRoles = getConfig(state, 'strategicRoles');
  const company = currentUser?.companiesAttributes[0];
  const pricingResult = getPricingCalculationResult(state);
  const valueSelector = formValueSelector(FORM_NAME);
  const currency = getCurrencyById(state, company?.currencyId);

  const {
    productId,
    desiredSalary,
    hoursPerDay,
    daysPerWeek,
    fixedMonthlyExpenses,
    productCost,
    productPreparationTime,
    profitMargin,
    strategicRoleId,
    productCategoryId,
    averageCost,
    averagePrice,
    averageProfitMargin,
  } = valueSelector(
    state,
    'productId',
    'desiredSalary',
    'hoursPerDay',
    'daysPerWeek',
    'fixedMonthlyExpenses',
    'productCost',
    'productPreparationTime',
    'profitMargin',
    'strategicRoleId',
    'productCategoryId',
    'averageCost',
    'averagePrice',
    'averageProfitMargin',
  );

  const product = getProduct(state, productId);
  const productTotalCost = getProductTotalCost(state, { productId });
  const productCategory = getConfigById(
    state,
    'productCategories',
    productCategoryId,
  );

  const strategicRole = getStrategicRoleById(state, strategicRoleId);

  const workHourCost = desiredSalary / (hoursPerDay * daysPerWeek * 4);

  const fixedHourCost = fixedMonthlyExpenses / (hoursPerDay * daysPerWeek * 4);

  const finalProductCost =
    productCost +
    (workHourCost + fixedHourCost) * (productPreparationTime / 60);

  const finalProductPrice =
    finalProductCost + (finalProductCost * profitMargin) / 100;

  return {
    initialValues: {
      desiredSalary: company?.desiredSalary,
    },
    currentUser,
    products,
    productCategories,
    strategicRoles,
    desiredSalary,
    hoursPerDay,
    daysPerWeek,
    fixedMonthlyExpenses,
    profitMargin,
    product,
    productTotalCost,
    workHourCost,
    fixedHourCost,
    finalProductCost,
    finalProductPrice,
    strategicRole,
    productCategory,
    averageCost,
    averagePrice,
    averageProfitMargin,
    pricingResult,
    currency,
  };
}

export default connect(mapStateToProps)(
  form.connect({
    onSubmit: calculateProductPricing,
  })(PricingCalculatorFormContainer),
);
