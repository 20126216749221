import { createSelector } from 'reselect';
import { orderByIdDesc } from '../utils/orderByCreatedAt';
import orderByDate from '../utils/orderByDate';
import orderByValue from '../utils/orderByValue';

const getStatements = (state) => state.entities.statements;
const getId = (_, id) => id;
const getOrderId = (_, orderId) => orderId;
const getType = (_, type) => type;
const getStatementsList = (state) => state.statementsList;
const getFetchedIds = (state) => state.statementsList.statementIds;

export const getOrdersAverageAmount = createSelector(
  getStatementsList,
  (statementsList) => {
    return statementsList?.ordersAverageAmount;
  },
);

export const getOrdersAverageProfitMargin = createSelector(
  getStatementsList,
  (statementsList) => {
    return statementsList?.ordersAverageProfitMargin;
  },
);

export const getTotalProductsStockAmount = createSelector(
  getStatementsList,
  (statementsList) => {
    return statementsList?.totalProductsStockAmount;
  },
);

export const getProductsCategoryRanking = createSelector(
  getStatementsList,
  (statementsList) => {
    return Object.values(statementsList?.productSalesByCategory)
      .filter((category) => category.productsSales > 0)
      .sort(orderByValue('productsSales'));
  },
);

export const getOrdersReasonRanking = createSelector(
  getStatementsList,
  (statementsList) => {
    return Object.values(statementsList?.ordersReasonsRanking)
      .filter((reason) => reason.ordersSales > 0)
      .sort(orderByValue('ordersSales'));
  },
);

export const getStatementPageInfo = createSelector(
  getStatementsList,
  (statementsList) => ({
    currentPage: statementsList.currentPage,
    totalPages: statementsList.totalPages,
    filters: statementsList.filters,
    loading: statementsList.loading,
  }),
);

export const getAllStatements = createSelector(getStatements, (statements) =>
  Object.values(statements),
);

export const getAllRevenues = createSelector(getAllStatements, (statements) =>
  statements.filter((statement) => statement.type == 'revenue'),
);

export const getAllExpenses = createSelector(getAllStatements, (statements) =>
  statements.filter((statement) => statement.type == 'expense'),
);

export const getStatement = createSelector(
  getStatements,
  getId,
  (statements, id) => statements[id],
);

export const getStatementsByFetchedIds = createSelector(
  getAllStatements,
  getFetchedIds,
  (statements, ids) =>
    statements
      .filter((statement) => ids.includes(statement.id))
      .sort(orderByDate('createdAt')),
);

export const getFetchedStatementsByType = createSelector(
  getStatementsByFetchedIds,
  getType,
  (statements, type) =>
    statements.filter((statement) => statement.type == type),
);

export const getLastStatementsByType = createSelector(
  getFetchedStatementsByType,
  (statements) => statements.sort(orderByIdDesc),
);

export const getStatementsTotal = createSelector(
  getFetchedStatementsByType,
  (statements) =>
    statements.reduce((acc, statement) => {
      const value = statement?.amount || 0;
      return value + acc;
    }, 0),
);

export const getTopStatements = createSelector(
  getFetchedStatementsByType,
  (statements) => statements.sort(orderByValue('amount')).slice(0, 5),
);

export const getOrderStatements = createSelector(
  getAllStatements,
  getOrderId,
  (statements, id) =>
    statements.filter((statement) => statement?.orderId == id),
);
