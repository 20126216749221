import { normalize, schema } from 'normalizr';
import { apiGet, apiPut } from './helpers/api';
import middleware from './helpers/railsMiddleware';
import { put } from 'superagent';
import { SubmissionError } from 'redux-form';
import { notifySubmitSucceeded } from './notifierProcesses';

const backgroundTask = new schema.Entity('backgroundTasks');

const isFile = (obj) => obj && !!obj.lastModified;

export const fetchBackgroundTasks = (dispatch) => {
  return apiGet('/api/v1/background_tasks').then((response) => {
    dispatch({
      type: 'BACKGROUND_TASKS_FETCHED',
      ...normalize(response.body.data, new schema.Array(backgroundTask)),
    });
  });
};

export const fetchBackgroundTask = (dispatch, { id }) => {
  return apiGet(`/api/v1/background_tasks/${id}`).then((response) => {
    dispatch({
      type: 'BACKGROUND_TASK_FETCHED',
      ...normalize(response.body.data, backgroundTask),
    });
  });
};

export const importSupplies = ({ file }, dispatch, props) => {
  if (!isFile(file)) return;

  return put('/api/v1/background_tasks/import_supplies')
    .use(middleware)
    .attach('import', file)
    .then((response) => {
      props.closeImportModal();
      notifySubmitSucceeded(dispatch, 'success', 'Planilha enviada!');
      dispatch({
        type: 'STARTED_IMPORT_SUPPLIES',
        ...normalize(response.body.data, backgroundTask),
      });
    })
    .catch((err) => {
      throw new SubmissionError({ _error: err.response?.body?.errors });
    });
};

export const generatePricing = (dispatch) => {
  return apiPut('/api/v1/background_tasks/pricing').then((response) => {
    dispatch({
      type: 'PRICING_GENERATION_STARTED',
      ...normalize(response.body.data, backgroundTask),
    });
  });
};
