import React from 'react';
import Box from './Box';
import Grid from './Grid';
import { spacing, utilityColors } from './theme';
import Typography from './Typography';
import ResultListItem from './ResultListItem';

const containerSx = {
  marginTop: spacing.s,
  borderRadius: '10px',
  padding: '13px 16px',
  backgroundColor: `${utilityColors.information}`,
  color: utilityColors.information,
};
const typographyColor = {
  color: '#ffffff',
};

export default function ResultInfo({ resultData }) {
  const { cost, price, currency } = resultData;

  return (
    <Box sx={containerSx}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" weight="bolder" sx={typographyColor}>
              Resultado
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ResultListItem
              label="Custo final do produto:"
              value={cost}
              toBRL
              currency={currency}
            />
          </Grid>
          <Grid item xs={12}>
            <ResultListItem
              label="Preço final do produto:"
              value={price}
              toBRL
              currency={currency}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
