import React, { useCallback, useEffect } from 'react';
import { replace } from 'connected-react-router';
import { connect } from 'react-redux';
import {
  getFetchedStatementsByType,
  getStatementPageInfo,
} from '../../selectors/statementSelectors';
import RevenuesPage from './RevenuesPage';
import { fetchCustomers } from '../../processes/customerProcessess';
import { fetchStatements } from '../../processes/statementProcesses';

function RevenuesPageContainer(props) {
  const { dispatch, filters } = props;

  useEffect(() => {
    filters?.typeEq == 'revenue' && fetchStatements(dispatch, { filters });
  }, [dispatch, filters]);

  useEffect(() => {
    fetchCustomers(dispatch);
  }, [dispatch]);

  const onAdd = useCallback(() => {
    dispatch(replace('/statements/revenues/new'));
  }, [dispatch]);

  return <RevenuesPage onAdd={onAdd} {...props} />;
}

function mapStateToProps(state) {
  const { filters, loading } = getStatementPageInfo(state);

  return {
    revenues: getFetchedStatementsByType(state, 'revenue'),
    loading,
    filters,
  };
}

export default connect(mapStateToProps)(RevenuesPageContainer);
