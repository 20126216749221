import { createSelector } from 'reselect';
import orderByDate from '../utils/orderByDate';

const getBackgroundTasks = (state) => state.entities.backgroundTasks;

export const getAllBackgroundTasks = createSelector(
  getBackgroundTasks,
  (tasks) => Object.values(tasks).sort(orderByDate('createdAt')),
);

export const getSuppliesImports = createSelector(
  getAllBackgroundTasks,
  (tasks) => tasks?.filter((task) => task.taskType == 'import_supplies'),
);

export const getPricings = createSelector(getAllBackgroundTasks, (tasks) =>
  tasks?.filter((task) => task.taskType == 'pricing'),
);

export const getLastPricingTask = createSelector(getPricings, (pricings) =>
  pricings.pop(),
);
